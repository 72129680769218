<template>
<SobreNosotros />
</template>


<script>
import SobreNosotros from '@/components/SobreNosotros.vue'

export default {
 
  name: 'Nosotros',
  
  components: {
    SobreNosotros, 
  },

  data: () => ({
  //
  }),

};
</script>

<style>

</style>